import {
    checkIfInView,
    addAnimationClass,
    scrollPosition,
    markAsAnimated,
    sectionIsAnimated
} from './../../helper/viewPort'

let topMenuRowDelay = 1
let stickyBtnDelay = 500

jQuery(window).scroll(function () {
    animateTopMenu()
    animateStickyBtn()
    animateFooterTop()
    animateFooter()
})

animateStickyBtn()

if (scrollPosition() <= 50) {
    animateTopMenu()
}


if (document.getElementById('footerTop') && checkIfInView('#footerTop', true, -100)) {
    animateFooterTop()
}

if (checkIfInView('.footer-bottom')) {
    animateFooter()
}

function animateTopMenu() {
    if (checkIfInView('#topMenuRow') && !sectionIsAnimated('topMenuRow')) {
        setTimeout(() => {
            addAnimationClass('#topMenuRow', 'animate__fromTopToBottom')
            markAsAnimated('topMenuRow')
        }, topMenuRowDelay);
    }
}

function animateStickyBtn() {
    if (checkIfInView('#stickyBtn') && !sectionIsAnimated('stickyBtn')) {
        setTimeout(() => {
            addAnimationClass('#stickyBtn', 'animate__fromRightToLeft')
            markAsAnimated('stickyBtn')
        }, stickyBtnDelay);
    }
}

function animateFooterTop() {
    if (document.getElementById('footerTop')) {
        if (checkIfInView('#footerTop') && !sectionIsAnimated('#footerTop')) {
            addAnimationClass('#footerTop .-bg', 'animate__fadeIn')

            setTimeout(() => {
                addAnimationClass('#footerTop .col-left .question', 'animate__fromBottomToTop')
            }, 200);
            setTimeout(() => {
                addAnimationClass('#footerTop .col-left .title', 'animate__fromBottomToTop')
            }, 400);
            setTimeout(() => {
                addAnimationClass('#footerTop .col-right', 'animate__fromBottomToTop')
            }, 600);
            markAsAnimated('footerTop')
        }
    }
}

function animateFooter() {
    if (checkIfInView('.footer-bottom', true, -85) && !sectionIsAnimated('footer-bottom')) {
        addAnimationClass('.footer-bottom', 'animate__fadeIn')
        markAsAnimated('footer-bottom')
    }
}
