import {
    checkIfInView,
    addAnimationClass,
    markAsAnimated,
    sectionIsAnimated
} from './../../helper/viewPort'

startAnimate()

jQuery(window).on('scroll', () => {
    startAnimate()
})

function startAnimate() {
    animateHeaderTitle()
    animateHeaderDescription()
    animateMobileDescription()
    animateProductInformation()
    animateProductImage()
    animateProductBottomInformation()
}


function animateHeaderTitle() {
    setTimeout(() => {
        if (checkIfInView('#productPageTitle') && !sectionIsAnimated('#productPageTitle')) {
            addAnimationClass('#productPageTitle', 'animate__fromLeftToRight')
            markAsAnimated('#productPageTitle')
        }
    }, 250);
}

function animateHeaderDescription() {
    setTimeout(() => {
        if (checkIfInView('#productPageDescription') && !sectionIsAnimated('#productPageDescription')) {
            addAnimationClass('#productPageDescription', 'animate__fromRightToLeft')
            markAsAnimated('#productPageDescription')
        }
    }, 400);
}
function animateMobileDescription() {
    setTimeout(() => {
        if (checkIfInView('#mobileDescription') && !sectionIsAnimated('#mobileDescription')) {
            addAnimationClass('#mobileDescription', 'animate__fromBottomToTop')
            markAsAnimated('#mobileDescription')
        }
    }, 400);
}

function animateProductInformation() {
    jQuery('.about-prod .right .info').each(function () {
        let elId = '#' + jQuery(this).attr('id')
        if (checkIfInView(elId) && !sectionIsAnimated(elId)) {
            addAnimationClass(elId, 'animate__fromBottomToTop')
            markAsAnimated(elId)
        }
    })
}
function animateProductImage() {
    if (checkIfInView('#productImages') && !sectionIsAnimated('#productImages')) {
        addAnimationClass('#productImages', 'animate__fromBottomToTop')
        markAsAnimated('#productImages')
    }
}
function animateProductBottomInformation() {
    if (checkIfInView('#productInformation') && !sectionIsAnimated('#productInformation')) {
        addAnimationClass('#productInformation', 'animate__fromLeftToRight')
        markAsAnimated('#productInformation')
    }
}
