require('./../plugins/slick')

jQuery('.product-list-inner').slick({
    lazyLoad: 'progressive',
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
        {
            breakpoint: 576,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                dots: false
            }
        },
        {
            breakpoint: 440,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: false
            }
        }
    ]

});

/**
 * Cal resize function on window resize
 */
jQuery(window).on('resize', function () {
    resizeProductList()
})

/**
 * Set product list height when page loaded or on window resize
 *
 * @param {Integer} timeout
 */
function resizeProductList(timeout = 500) {
    setTimeout(() => {
        let width = jQuery(window).width()
        let addHeight = 40

        if (width <= 576) {
            addHeight = 0
        }

        let card = jQuery('.product-card').height()
        jQuery('#productList').height(card + addHeight)
    }, timeout);
}

/**
 * Call function when page loaded
 */
resizeProductList(100)


