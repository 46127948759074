require('./bootstrap');

import Vue from 'vue';


window.Vue = require('vue');

// Vue.component('full-page-scroll', FullPageScroll)

Vue.config.productionTip = false

const app = new Vue({
    el: '#app',
});

// Load scripts by page
require('./loadScripts');
