import {
    checkIfInView,
    addAnimationClass,
    markAsAnimated,
    sectionIsAnimated
} from './../../helper/viewPort'

startAnimate()

jQuery(window).on('scroll', () => {
    startAnimate()
})

function startAnimate() {
    animateHeaderTitle()
    animateHeaderDescription()
    animateProducts()
}

function animateHeaderTitle() {
    setTimeout(() => {
        if (checkIfInView('#productPageTitle') && !sectionIsAnimated('#productPageTitle')) {
            addAnimationClass('#productPageTitle', 'animate__fromLeftToRight')
            markAsAnimated('#productPageTitle')
        }
    }, 250);
}

function animateHeaderDescription() {
    setTimeout(() => {
        if (checkIfInView('#productPageDescription') && !sectionIsAnimated('#productPageDescription')) {
            addAnimationClass('#productPageDescription', 'animate__fromRightToLeft')
            markAsAnimated('#productPageDescription')
        }
    }, 400);
}

function animateProducts() {
    jQuery('.product-row').each(function () {
        let elId = '#' + jQuery(this).attr('id')
        if (checkIfInView(elId) && !sectionIsAnimated(elId)) {
            addAnimationClass(elId, 'animate__fromBottomToTop')
            markAsAnimated(elId)
        }
    })
}
