import { checkIfInView,
    addAnimationClass,
    markAsAnimated,
    sectionIsAnimated,
    windowWidth
} from './../../helper/viewPort'

startAnimate()

jQuery(window).on('scroll', () => {
    startAnimate()
})

function startAnimate() {
    // animateHeaderTitle()
    // animateContactsRow()
    animateContactForm()
    animateContactInfo()
}

function animateHeaderTitle() {
    setTimeout(() => {
        if (checkIfInView('#contactUsTitle') && !sectionIsAnimated('#contactUsTitle')) {
            addAnimationClass('#contactUsTitle', 'animate__fromBottomToTop')
            markAsAnimated('#contactUsTitle')
        }
    }, 250);
}

function animateContactsRow() {
    let count = 1
    let delay = 100
    jQuery('.contacts-row.-first .contact').each(function () {
        let elId = '#' + jQuery(this).attr('id')

        if (windowWidth() >= 992) {
            setTimeout(() => {
                animateContact(elId)
            }, delay * count);

            count++
        } else {
            animateContact(elId)
        }
    })
}

function animateContact(elId) {
    if (checkIfInView(elId) && !sectionIsAnimated(elId)) {
        addAnimationClass(elId, 'animate__fromLeftToRight')
        markAsAnimated(elId)
    }
}

function animateContactForm() {
    if (checkIfInView('#contactFormContainer') && !sectionIsAnimated('#contactFormContainer')) {
        addAnimationClass('#contactFormContainer', 'animate__fromRightToLeft')
        markAsAnimated('#contactFormContainer')
    }
}

function animateContactInfo() {
    setTimeout(() => {
        if (checkIfInView('#contactFormCard') && !sectionIsAnimated('#contactFormCard')) {
            addAnimationClass('#contactFormCard', 'animate__fromRightToLeft')
            markAsAnimated('#contactFormCard')
        }
    }, 200);
}
