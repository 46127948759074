import { addAnimationClass } from './../helper/viewPort'

jQuery('#accept_cookie').on('click', () => {
    setCookie('cookie_accept', true)
    hideCookieNotification()
})

if(getCookie('cookie_accept') === null ) {
    showCookieNotification()
}

function showCookieNotification() {
    jQuery('#cookie_accept').addClass('active')
    setTimeout(() => {
        addAnimationClass('#cookie_accept', 'animate__fromBottomToTop')
    }, 100);
}
function hideCookieNotification() {
    addAnimationClass('#cookie_accept', 'animate__fromBottomToTop', true)
    addAnimationClass('#cookie_accept', 'animate__FadeOutFromCurrentPosition')
    setTimeout(() => {
        jQuery('#cookie_accept').removeClass('active')
    }, 800);
}

function setCookie(name, value, days = null) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}
function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}
