require('./../plugins/fullpage/easings')
require('./../plugins/fullpage/scrolloverflow')
const fullpage = require('../plugins/fullPage/fullpage')
let showPreloader = true

let animatedElements = [];

let fullPage = new fullpage('#fullPageScroll', {
    licenseKey: '^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$',
    lazyLoading: true,
    scrollingSpeed: 500,
    afterLoad: function (origin, destination, direction) {
        if (showPreloader) {
            setTimeout(() => {
                showPreloader = false
                setTimeout(() => {
                    jQuery('#preeloader').remove()
                    animateElements(destination.anchor)
                }, 400);
            }, 3000);
        } else {
            animateElements(destination.anchor)
        }
    }
});

/**
 * Run animation by current index
 * @param {Number} index Current section index
 */
function animateElements(anchor) {
    switch (anchor) {
        case 'section-1':
            if (!sectionIsAnimated(anchor)) {
                animateSection1()
            }
            break;
        case 'section-2':
            if (!sectionIsAnimated(anchor)) {
                animateSection2()
            }
            break;
        case 'section-3':
            if (!sectionIsAnimated(anchor)) {
                animateSection3()
            }
            break;
        case 'section-4':
            if (!sectionIsAnimated(anchor)) {
                animateSection4()
            }
            break;
        case 'section-5':
            if (!sectionIsAnimated(anchor)) {
                animateSection5()
            }
            break;
        case 'section-6':
            if (!sectionIsAnimated(anchor)) {
                animateSection6()
            }
            break;
        default:
            break;
    }

    if (anchor === 'section-4') {
        startSwitchSlides()
    } else {
        stopSwitchSlides()
    }

    if (!sectionIsAnimated('#stickyBtn')) {
        animateStickyBtn()
    }

    if(anchor !== 'section-1') {
        showScrollToTop()
    } else {
        hideScrollToTop()
    }

    markAsAnimated(anchor)
}

function markAsAnimated(index) {
    if (!sectionIsAnimated(index)) {
        animatedElements.push(index)
    }
}

function sectionIsAnimated(index) {
    return animatedElements.includes(index)
}

/**
 * Animations
 */

function animateStickyBtn() {
    setTimeout(() => {
        addAnimationClass('#stickyBtn', 'animate__fromRightToLeft')
        markAsAnimated('#stickyBtn')
    }, 500);
}

function animateSection1() {
    addAnimationClass('#topMenuRow', 'animate__fromTopToBottom')
    setTimeout(() => {
        addAnimationClass('#section1Title', 'animate__fromBottomToTop')
    }, 300);
}

function animateSection2() {
    addAnimationClass('#section2Box', 'animate__rolBgFromLeft')
    setTimeout(() => {
        addAnimationClass('#section2title', 'animate__fromBottomToTop')
    }, 300);
    setTimeout(() => {
        addAnimationClass('#section2Img', 'animate__fromLeftToRight')
    }, 600);
    setTimeout(() => {
        addAnimationClass('#section2text', 'animate__fromRightToLeft')
    }, 800);
}

function animateSection3() {
    addAnimationClass('#section3Bg', 'animate__fadeIn')
    setTimeout(() => {
        addAnimationClass('#productList .section-title', 'animate__section3Title')
    }, 300);
    setTimeout(() => {
        addAnimationClass('#section3Card3', 'animate__fromRightToLeft')
        setTimeout(() => {
            addAnimationClass('#section3Card3 .title', 'animate__fromRightToLeft')
        }, 200);
    }, 500);
    setTimeout(() => {
        addAnimationClass('#section3Card2', 'animate__fromRightToLeftExtra')
        setTimeout(() => {
            addAnimationClass('#section3Card2 .title', 'animate__fromRightToLeft')
        }, 200);
    }, 700);
    setTimeout(() => {
        addAnimationClass('#section3Card1', 'animate__fromRightToLeft')
        setTimeout(() => {
            addAnimationClass('#section3Card1 .title', 'animate__fromRightToLeft')
        }, 200);
    }, 900);
}

function animateSection4() {
    addAnimationClass('#section4card', 'animate__section4Card')
    setTimeout(() => {
        addAnimationClass('#section4Card1 .title', 'animate__fromRightToLeft')
        setTimeout(() => {
            addAnimationClass('#section4Card1 .des-title', 'animate__fromRightToLeft')
        }, 200);
        setTimeout(() => {
            addAnimationClass('#section4Card1 .description', 'animate__fromRightToLeft')
        }, 400);
    }, 800);
}

function animateSection5() {
    addAnimationClass('#section5Bg', 'animate__fadeIn')

    setTimeout(() => {
        addAnimationClass('#section5Title', 'animate__fromBottomToTop')
    }, 100);

    setTimeout(() => {
        addAnimationClass('#cert4', 'animate__fromRightToLeft')
        setTimeout(() => {
            addAnimationClass('#cert4 .title', 'animate__fromRightToLeft')
        }, 200);
    }, 300);
    setTimeout(() => {
        addAnimationClass('#cert3', 'animate__fromRightToLeft')
        setTimeout(() => {
            addAnimationClass('#cert3 .title', 'animate__fromRightToLeft')
        }, 200);
    }, 500);
    setTimeout(() => {
        addAnimationClass('#cert2', 'animate__fromRightToLeft')
        setTimeout(() => {
            addAnimationClass('#cert2 .title', 'animate__fromRightToLeft')
        }, 200);
    }, 700);
    setTimeout(() => {
        addAnimationClass('#cert1', 'animate__fromRightToLeft')
        setTimeout(() => {
            addAnimationClass('#cert1 .title', 'animate__fromRightToLeft')
        }, 200);
    }, 900);
    setTimeout(() => {
        addAnimationClass('#section5ArrowLeft', 'animate__fadeIn')
        addAnimationClass('#section5ArrowRight', 'animate__fadeIn')
    }, 1100);
}

function animateSection6() {
    addAnimationClass('#section6Bg', 'animate__fadeIn')
    setTimeout(() => {
        addAnimationClass('#section6Left', 'animate__fromLeftToRight')
        addAnimationClass('#section6Right', 'animate__fromRightToLeft')
    }, 300);
    setTimeout(() => {

    }, 300);
    setTimeout(() => {
        addAnimationClass('#section6footer', 'animate__fromBottomToTop')
    }, 500);
}

/**
 * END Animations
 */

function addAnimationClass(to, animationClass, remove = false) {
    if (remove) {
        let el = jQuery(to).removeClass(animationClass)
    } else {
        let el = jQuery(to).addClass(animationClass)
    }
}

let currentSlide = 1;
let slideTitles = jQuery('#animate-title').data('animation-info')
let slideDes = jQuery('#animate-des').data('animation-info')
let slideLoop = null

function switchSlides() {
    if (slideTitles.length === currentSlide) {
        currentSlide = 0
    }

    addAnimationClass('#section4Card1 .title', 'animate__fromRightToLeft', true)
    addAnimationClass('#section4Card1 .title', 'animate__fadeOut')
    setTimeout(() => {
        addAnimationClass('#section4Card1 .description', 'animate__fromRightToLeft', true)
        addAnimationClass('#section4Card1 .description', 'animate__fadeOut')
    }, 200);

    setTimeout(() => {
        jQuery('#animate-title').text(slideTitles[currentSlide])
        jQuery('#animate-des').text(slideDes[currentSlide])

        jQuery('.navigation .circle.-active').removeClass('-active')
        jQuery("div[data-slide='" + currentSlide +"']").addClass('-active')

        addAnimationClass('#section4Card1 .title', 'animate__fadeOut', true)
        addAnimationClass('#section4Card1 .title', 'animate__fromRightToLeft')
        setTimeout(() => {
            addAnimationClass('#section4Card1 .description', 'animate__fadeOut', true)
            addAnimationClass('#section4Card1 .description', 'animate__fromRightToLeft')

            currentSlide++
        }, 200);
    }, 800);
}

function startSwitchSlides() {
    slideLoop = setInterval(() => {
        switchSlides()
    }, 9000);
}

function stopSwitchSlides() {
    clearInterval(slideLoop);
}

jQuery('.navigation .circle').on('click', function () {
    switchSlideOnClick(jQuery(this))
})

function switchSlideOnClick(el) {
    stopSwitchSlides()

    jQuery('.navigation .circle.-active').removeClass('-active')
    el.addClass('-active')

    switchSlides()
    startSwitchSlides()
}


// Scroll to top button
jQuery('#scrollToTop').on('click', () => {
    moveToFirstSection()
})

function showScrollToTop() {
    jQuery('#scrollToTop').addClass('active')
}

function hideScrollToTop() {
    jQuery('#scrollToTop').removeClass('active')
}

function moveToFirstSection() {
    fullPage.moveTo('section-1')
}

// END Scroll to top button
