require('./../plugins/slick')
import {  addAnimationClass } from './../helper/viewPort'

let imageList = []
let openImageIndex = 0

jQuery('.img-list').slick({
    lazyLoad: 'progressive',
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    prevArrow: $('#singleProdImgPrev'),
    nextArrow: $('#singleProdImgNext'),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 4,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 360,
        settings: {
          slidesToShow: 2,
        }
      }
    ]
});

addClickEventOnImages()
resizeMainContHeight();

jQuery(window).on('resize', () => {
    resizeMainContHeight()
    addClickEventOnImages()
})

// when click on main image open gallery and set current image index
jQuery('#mainImage').on('click', function () {
    // gel gallery image list
    imageList = jQuery('#galleryImage').data('image-list')
    let currentImage = jQuery('#mainImage').data('current')

    // set current open image index
    openImageIndex = imageList.findIndex(el => el === currentImage)

    // show gallery with current image
    jQuery('#galleryImage').attr('src', jQuery(this)[0].currentSrc)
    jQuery('#imageGallery').addClass('-active')

    addAnimationClass('#imageGallery', 'animate__fadeOutSimple', true)
    addAnimationClass('#imageGallery', 'animate__fadeIn')
})

// Switch to prev gallery image
jQuery('#prevGalleryImage').on('click', () => {
    prevGalleryImage()
})

// switch to next gallery image
jQuery('#nextGalleryImage').on('click', () => {
    nextGalleryImage()
})

// close gallery screen
jQuery('#closeGallery').on('click', () => {
    addAnimationClass('#imageGallery', 'animate__fadeIn', true)
    addAnimationClass('#imageGallery', 'animate__fadeOutSimple')
    setTimeout(() => {
        jQuery('#imageGallery').removeClass('-active')
    }, 400);
})
// ALL FUNCTIONS

/**
 * Resize main image container height by container width
 */
function resizeMainContHeight() {
    let width = jQuery('#mainImage').width()
    jQuery('#mainImage').attr('height', width)
}

/**
 * Change main image from image list
 */

function addClickEventOnImages() {
    jQuery('#imgList img').on('click', function () {
        let currentUrl = jQuery(this)[0].currentSrc
        jQuery('#mainImage').attr('src', currentUrl)

        let currentImgName = currentUrl.split('/img/products/')[1]
        jQuery('#mainImage').attr('data-current', currentImgName)
    })
}

/**
 * Switch to next gallery image
 */
function nextGalleryImage() {
    openImageIndex++

    if (openImageIndex === imageList.length) {
        openImageIndex = 0
    }

    switchGalleryImage()
}

/**
 * Switch to prev gallery image
 */
function prevGalleryImage() {
    openImageIndex--

    if (openImageIndex < 0) {
        openImageIndex = (imageList.length - 1)
    }

    switchGalleryImage()
}

/**
 * Show image by selected image index
 */
function switchGalleryImage() {
    jQuery('#galleryImage').attr('src', '/img/products/' + imageList[openImageIndex])
}
