import {
    checkIfInView,
    addAnimationClass,
    scrollPosition,
    markAsAnimated,
    sectionIsAnimated
} from './../../helper/viewPort'

let headerTextDelay = 250
let mapDelay = 100
let mapInfoDelay = 400

jQuery(window).scroll(function () {
    animateHeaderText()
    animateWorldMap()
    animateAboutUsDescription()
    animateAboutUsDescriptionImg()
    animateAboutUsProductsSlide()
    animateAboutUsProductsDes()
    animateAboutUsProductsTitle()
})

if (scrollPosition() <= 100) {
    animateHeaderText()
}

if (checkIfInView('#worldMap')) {
    animateWorldMap()
}

if (checkIfInView('#aboutUsDes', true, -500)) {
    animateAboutUsDescription()
}

if (checkIfInView('#aboutUsImg')) {
    animateAboutUsDescriptionImg()
}

if (checkIfInView('#aboutProductsSlider')) {
    animateAboutUsProductsSlide()
}
if (checkIfInView('#aboutProductsDes')) {
    animateAboutUsProductsDes()
}
if (checkIfInView('#aboutProductsTitle')) {
    animateAboutUsProductsTitle()
}

function animateHeaderText() {
    if (checkIfInView('#about-us .head-content .columns-1 h1') && !sectionIsAnimated('about-us .head-content .columns-1 h1')) {
        setTimeout(() => {
            addAnimationClass('#about-us .head-content .columns-1', 'animate__fromRightToLeft')
            markAsAnimated('about-us .head-content .columns-1 h1')
        }, headerTextDelay);
    }
}

function animateWorldMap() {
    if (checkIfInView('#worldMap') && !sectionIsAnimated('#worldMap')) {
        addAnimationClass('#worldMap > .title', 'animate__fromBottomToTop')

        setTimeout(() => {
            addAnimationClass('#worldMap .map .world-map', 'animate__fromRightToLeft')
        }, mapDelay);
        setTimeout(() => {
            addAnimationClass('#worldMap .info.-1', 'animate__fromLeftToRight')
            setTimeout(() => {
                addAnimationClass('#worldMap .info.-2', 'animate__fromLeftToRight')
            }, 200);
            setTimeout(() => {
                addAnimationClass('#worldMap .info.-3', 'animate__fromLeftToRight')
            }, 400);
        }, mapInfoDelay);

        markAsAnimated('#worldMap')
    }
}

function animateAboutUsDescription() {
    if (checkIfInView('#aboutUsDes', true, -500) && !sectionIsAnimated('#aboutUsDes')) {
        addAnimationClass('#aboutUsDes .title', 'animate__fromLeftToRight')
        setTimeout(() => {
            addAnimationClass('#aboutUsDes .description:nth-child(2)', 'animate__fromLeftToRight')
        }, 150);
        setTimeout(() => {
            addAnimationClass('#aboutUsDes .description:nth-child(3)', 'animate__fromLeftToRight')
        }, 300);
        setTimeout(() => {
            addAnimationClass('#aboutUsDes .description:nth-child(4)', 'animate__fromLeftToRight')
        }, 450);

        markAsAnimated('#aboutUsDes')
    }
}

function animateAboutUsDescriptionImg() {
    if (checkIfInView('#aboutUsImg', true, -200) && !sectionIsAnimated('#aboutUsImg')) {
        addAnimationClass('#aboutUsImg .img-2', 'animate__fromRightToLeft')
        setTimeout(() => {
            addAnimationClass('#aboutUsImg .img-1', 'animate__fromLeftToRight')
        }, 400);

        markAsAnimated('#aboutUsImg')
    }
}

function animateAboutUsProductsSlide() {
    if (checkIfInView('#aboutProductsSlider', true, -200) && !sectionIsAnimated('#aboutProductsSlider')) {
        addAnimationClass('#aboutProductsSlider', 'animate__fromLeftToRight')

        markAsAnimated('#aboutProductsSlider')
    }
}
function animateAboutUsProductsDes() {
    if (checkIfInView('#aboutProductsDes', true, -200) && !sectionIsAnimated('#aboutProductsDes')) {
        addAnimationClass('#aboutProductsDes', 'animate__fromBottomToTop')

        markAsAnimated('#aboutProductsDes')
    }
}
function animateAboutUsProductsTitle() {
    if (checkIfInView('#aboutProductsTitle', true, -200) && !sectionIsAnimated('#aboutProductsTitle')) {
        addAnimationClass('#aboutProductsTitle', 'animate__fromRightToLeft')

        markAsAnimated('#aboutProductsTitle')
    }
}
