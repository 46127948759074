let animatedElements = []

export function checkIfInView(id, animateFromPageBottom = false, fromBottom = 0) {
    if (animateFromPageBottom) {
        var top_of_element = jQuery(id).offset().top;
        var bottom_of_element = jQuery(id).offset().top + jQuery(id).outerHeight();
        var bottom_of_screen = jQuery(window).scrollTop() + jQuery(window).innerHeight();
        var top_of_screen = jQuery(window).scrollTop();


        if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element) && (bottom_of_element < (bottom_of_screen - fromBottom))){
            return true
        }

        return false
    }

    var top_of_element = jQuery(id).offset().top;
    var bottom_of_element = jQuery(id).offset().top + jQuery(id).outerHeight();
    var bottom_of_screen = jQuery(window).scrollTop() + jQuery(window).innerHeight();
    var top_of_screen = jQuery(window).scrollTop();

    if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)){
        return true
    }

    return false
}

export function addAnimationClass(to, animationClass, remove = false) {
    if (remove) {
        let el = jQuery(to).removeClass(animationClass)
    } else {
        let el = jQuery(to).addClass(animationClass)
    }
}

export function markAsAnimated(index) {
    if (!sectionIsAnimated(index)) {
        animatedElements.push(index)
    }
}

export function sectionIsAnimated(index) {
    return animatedElements.includes(index)
}

export function scrollPosition() {
    return jQuery(window).scrollTop()
}

export function windowWidth() {
    return jQuery(window).width()
}
