// home page scroll if fullPageScroll element exists
if (document.getElementById('fullPageScroll')) {
    require('./scripts/homePageScrollFullPage')
}

// home page certificate slider
if (document.getElementById('homeSlickSlider')) {
    require('./scripts/homeSlider')
}

// production page tab switch script
if(document.getElementById('productsTabs')) {
    require('./scripts/productsTabSwitch')
}

// about us page certificate slider
if(document.getElementById('aboutUsSlider')) {
    require('./scripts/aboutUsPageSlider')
}

// home page product section slider
if(document.getElementById('productList')) {
    require('./scripts/homePageProductSlider')
}

// top menu row script
if (document.getElementById('topMenuRow')) {
    require('./scripts/menu')
}

// about us page world map animation
if (document.getElementById('worldMap')) {
    require('./scripts/worldMap')
}

// all pages global animations
if (document.getElementById('about-us') ||
    document.getElementById('products') ||
    document.getElementById('contact-us') ||
    document.getElementById('product-view') ||
    document.getElementById('privacy-policy')) {
    require('./scripts/animations/global')
}

// about us page animations
if (document.getElementById('about-us')) {
    require('./scripts/animations/aboutUs')
}

// single product page image slideshow
if (document.getElementById('imgList')) {
    require('./scripts/singleProductImgList')
}

// product list page animations
if (document.getElementById('products')) {
    require('./scripts/animations/productList')
}

// Single product animation page animations
if (document.getElementById('product-view')) {
    require('./scripts/animations/singleProduct')
}

// Contact us page animations
if (document.getElementById('contact-us')) {
    require('./scripts/animations/contactUs')
}

// Privacy policy page animations
if (document.getElementById('privacy-policy')) {
    require('./scripts/animations/privacyPolicy')
}

// if not home page and have element with id "scrollToTop" load script
if (document.getElementById('scrollToTop') && !document.getElementById('home')) {
    require('./scripts/scrollTop')
}

// Load custom select script if that field exists
if (document.getElementsByClassName('custom-select').length > 0) {
    require('./scripts/customSelect')
}

// Load cookie accept script if that element exists
if (document.getElementById('cookie_accept')) {
    require('./scripts/cookieAccept')
}
