import { checkIfInView,
    addAnimationClass,
    markAsAnimated,
    sectionIsAnimated,
    windowWidth
} from './../../helper/viewPort'

startAnimate()

jQuery(window).on('scroll', () => {
    startAnimate()
})

function startAnimate() {
    animateHeaderTitle()
}

function animateHeaderTitle() {
    setTimeout(() => {
        if (checkIfInView('#privacyPolicyTitle') && !sectionIsAnimated('#privacyPolicyTitle')) {
            addAnimationClass('#privacyPolicyTitle', 'animate__fromBottomToTop')
            markAsAnimated('#privacyPolicyTitle')
        }
    }, 250);
}
