jQuery(window).on('scroll', () => {
    showOrHideScrollToTop()
})

jQuery('#scrollToTop').on('click', () => {
    scrollToTop()
})

showOrHideScrollToTop()

function showOrHideScrollToTop() {
    let position = jQuery(window).scrollTop()

    if(position > 50) {
        jQuery('#scrollToTop').addClass('active')
    } else {
        jQuery('#scrollToTop').removeClass('active')
    }
}

function scrollToTop() {
    let speed = jQuery(window).scrollTop() / 1.5

    jQuery('html, body').animate({scrollTop: 0}, speed)
}