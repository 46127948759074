import { checkIfInView } from './../helper/viewPort'

let mapIsAnimating = false
let mapAnimation = null
let mapAnimationInterval = 3000
let currentSection = 1
let maxSections = 2

setTimeout(() => {
    if (checkIfInView('#worldMap', true, -100)) {
        startMapAnimation()
        mapIsAnimating = true;
    }

    jQuery(window).scroll(function () {
    if (checkIfInView('#worldMap')) {
        if (!mapIsAnimating) {
            mapIsAnimating = true
            startMapAnimation()
        }
    } else {
        if (mapIsAnimating) {
            mapIsAnimating = false
            stopMapAnimation()
        }
    }
})
}, 1500);

function startMapAnimation() {
    animateMap()

    mapAnimation = setInterval(() => {
        let lastSection = currentSection
        changeCurrentSection()
        animateMap(lastSection)
    }, mapAnimationInterval)
}

function stopMapAnimation() {
    clearInterval(mapAnimation)
}

function changeCurrentSection() {
    if (currentSection < maxSections) {
        currentSection++
    } else {
        currentSection = 1
    }
}

function animateMap(lastSection) {
    jQuery('#worldMap .info.active').removeClass('active')

    jQuery('#worldMap .info.-' + currentSection).addClass('active')

    if (currentSection !== lastSection) {
        let oldElements = jQuery('#worldMap .map-' + lastSection)
        oldElements.each((index) => {
            oldElements[index].removeAttribute('fill')
            oldElements[index].setAttribute('style', 'stroke: white; stroke-linejoin: round; fill-opacity:0.5; stroke-opacity:1')
        })
    }

    let elements = jQuery('#worldMap .map-' + currentSection)
    elements.each((index) => {
        elements[index].setAttribute('style', 'fill: #4B99C6; fill-opacity:1; stroke-opacity:0.5')
        elements[index].removeAttribute('stroke')
        elements[index].removeAttribute('stroke-linejoin')
    })
}
