// on click tab name in production page switch active tab
jQuery('#productsTabs li').on('click', function() {
    jQuery('#productsTabs li.active').removeClass('active')
    jQuery(this).addClass('active')
})

jQuery('#productsTabs li').on('click', function() {
    let slug = jQuery(this).data('slug')

    let topPosition = jQuery("#" + slug).offset().top
    let scrollSpeed = topPosition / 1.3

    jQuery('.product-row .shadow.-active').removeClass('-active')
    jQuery('.product-row .title.-active').removeClass('-active')

    jQuery('#' + slug + ' .shadow').addClass('-active')
    jQuery('#' + slug + ' .title').addClass('-active')

    jQuery('html, body').animate({
        scrollTop: topPosition
    }, scrollSpeed);
})